import React, { useState } from 'react';
import { Input } from '@chakra-ui/react';

// TODO:
type QuestionGroupFieldProps = {
  id: number;
  placeholder: string;
  handleChangeInputGroup?: (
    id: number,
    event: React.FormEvent<HTMLInputElement>
  ) => void;
  answers: string[];
  title: string;
};

const QuestionGroupField = ({
  id,
  placeholder,
  handleChangeInputGroup,
  answers,
  title,
}: QuestionGroupFieldProps) => {
  const [value, setValue] = useState((answers && answers[0]) || '');

  const isRequiredField = [
    'First name',
    'Last name',
    'Email address',
    'Primary phone',
  ].includes(placeholder);
  return (
    <Input
      placeholder={placeholder}
      value={value}
      onChange={(event) => {
        if (handleChangeInputGroup) {
          handleChangeInputGroup(id, event);
        }
        setValue(event.target.value);
      }}
      required={isRequiredField}
    />
  );
};

export default QuestionGroupField;
