import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Link,
  Center,
  Container,
  Flex,
  Text,
  Input,
  Button,
  VStack,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  HStack, Image
} from '@chakra-ui/react';
import { EmailIcon, LockIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setErrorMessage, signinUser } from '../ducks/auth/auth-slice';
import useLocalStorage from '../utilities/hooks/useLocalStorage';

const SigninSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  password: Yup.string()
    .min(6, 'Password entered is too short')
    .required('Password is required'),
});

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setValue } = useLocalStorage('user', '{}');
  const { isLoading, errorMessage } = useAppSelector((values) => values.auth);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SigninSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(signinUser(values))
        .unwrap()
        .then((res) => {
          setValue(res);
          navigate('/applications');
        })
        .catch(() => {
          dispatch(
            setErrorMessage(
              'Sorry, your password was incorrect. Please double-check your password.'
            )
          );
          setSubmitting(false);
        });
    },
  });

  return (
    <Flex h="100vh">
      <Box bg="#282828" display={{ base: 'none', md: 'flex'}} width="530px">
        <Image src="/images/logo-white.png" alt="truoffer logo" position="absolute" top="50" left="50" height="48px"/>
        <Center height="100%" color="white">
          <Container px="50px" mb="260px">
            <Text fontSize="42px" fontWeight="bold" fontFamily="Merriweather">Sign in to your account</Text>
            <Text>Welcome back! Sign in to get a free quote on your loan</Text>
          </Container>
        </Center>
        <Box position="absolute" bottom="50" left="50" color="white">
          <Flex mb="8">
            <Image src="/images/phone-white.png" alt="phone icon"  height="26px" mr="4"/>
            <Text>+639 123 456 7890</Text>
          </Flex>
          <Flex>
            <Image src="/images/mail-white.png" alt="phone icon"  height="26px" mr="4"/>
            <Text>info@truoffer.com</Text>
          </Flex>
        </Box>
      </Box>
      <Center flex="1">
        <Container>
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4} align="flex-start">
              <FormControl>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                {formik.touched.email && formik.errors.email && (
                  <Text fontSize="sm" color="red">
                    {formik.errors.email}
                  </Text>
                )}
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    <EmailIcon />
                  </InputLeftElement>
                  <Input
                    autoComplete="on"
                    id="email"
                    name="email"
                    type="email"
                    variant="filled"
                    placeholder="Enter email address"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">Password</FormLabel>
                {formik.touched.password && formik.errors.password && (
                  <Text fontSize="sm" color="red">
                    {formik.errors.password}
                  </Text>
                )}
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    <LockIcon />
                  </InputLeftElement>
                  <Input
                    autoComplete="on"
                    id="password"
                    name="password"
                    type="password"
                    variant="filled"
                    placeholder="Enter password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                </InputGroup>
              </FormControl>
              <HStack spacing={8}>
                <Button
                  width="180px"
                  isLoading={isLoading}
                  disabled={
                    !(formik.isValid && formik.dirty) || formik.isSubmitting
                  }
                  type="submit"
                  colorScheme="green"
                >
                  Sign in
                </Button>
                {errorMessage && (
                  <Text fontSize="sm" color="red">
                    {errorMessage}
                  </Text>
                )}
              </HStack>
              <Link
                to="/signup"
                as={RouterLink}
                fontSize="xs"
                color="gray.500"
              >
                Don&apos;t have an account? <strong>Signup</strong>
              </Link>
              <Link
                to="/forgot-password"
                as={RouterLink}
                fontSize="xs"
                color="gray.500"
              >
                Trouble signing in?
              </Link>
            </VStack>
          </form>
        </Container>
        <Image src="/images/logo.png" alt="phone icon"  height="32px" position="absolute" bottom="50" right="50"/>
      </Center>
    </Flex>
  );
};

 export default Signin