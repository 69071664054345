import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Flex,
  Text,
  Image
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import '../styles.css';

import {
  fetchQuestionPanels,
  setSelectedPanelID,
  fetchQuestionPanelsJoint
} from '../ducks/questionPanelsSlice';

export default function QuestionPanels() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isJoint = searchParams.get('co_borrower_id');

  const dispatch = useAppDispatch();
  const { questionPanels, selectedPanelID } = useAppSelector(
    (values) => values.questionPanels
  );

  useEffect(() => {
    if (isJoint) {
      dispatch(fetchQuestionPanelsJoint());
    } else {
      dispatch(fetchQuestionPanels());
    }
  }, []);

  useEffect(() => {
    if (questionPanels && questionPanels[0]) {
      dispatch(setSelectedPanelID(questionPanels[0].id));
    }
  }, [questionPanels]);

  return (
    <Box bg='white' transition='3s ease' w={{ base: 'full', md: 60 }} h='full'>
      <Accordion allowToggle defaultIndex={0}>
        <AccordionItem>
          <div>
            <AccordionButton aria-expanded="true">
              <Flex flex="1" textAlign='left'>
                <Image src='/images/question-icon.png' alt='Question Icon' height="26px" mr="4"/>
                <Text fontWeight='semibold'>Questions</Text>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
          </div>
          <AccordionPanel pb={4}>
            {questionPanels && questionPanels.map(link => (
              <Button
                key={link.id}
                variant="ghost"
                cursor="pointer"
                mb="15px"
                color={selectedPanelID === link.id ? 'gray.900' : 'gray.400'}
                onClick={() => dispatch(setSelectedPanelID(link.id))}
              >
                <Text fontSize="sm" fontWeight="500">{link.name}</Text>
              </Button>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
