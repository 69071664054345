import React, { useState } from 'react';
import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import QuestionGroupField from './QuestionGroupField';
import { useAppDispatch } from '../app/hooks';

type QuestionApplicationTypeFieldProps = {
  choices: any;
  question: any;
  answers: any;
  selectChoice: (
    questionId: number,
    nextQuestionId?: number | null | undefined,
    choiceId?: number,
    selected?: boolean,
    resetChoice?: boolean
  ) => void;
  handleChangeInputGroup?: (
    id: number,
    event: React.FormEvent<HTMLInputElement>
  ) => void;
};

const QuestionApplicationTypeField = ({
                                        choices,
                                        question,
                                        selectChoice,
                                        answers,
                                        handleChangeInputGroup
                                      }: QuestionApplicationTypeFieldProps) => {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState(answers && answers[0]);
  const [searchParams, setSearchParams] = useSearchParams();
  const isJoint = searchParams.get('co_borrower_id');

  if (isJoint) {
    return (
      <>
        <Text fontSize='4xl'>
          <strong>Borrower</strong>
        </Text>
        Application done!
        <br />
      </>
    );
  }

  return (
    <>
      {question.question_choices.map((choice: any) => (
        <Flex
          key={choice.id}
          alignItems='center'
        >
          {selected === choice.id && (
            <Image src='/images/check.png' alt='Checked Icon' height='14px' mr='2' ml='-8' />
          )}
          <Text
            size='md'
            fontWeight='semibold'
            color={selected === choice.id ? 'gray.900' : 'gray.500'}
            _active={{ color: 'gray.900' }}
            _hover={{ color: 'gray.900' }}
            cursor='pointer'
            onClick={() => {
              setSelected(choice.id);

              selectChoice(
                question.id,
                choice.next_question_id,
                choice.id,
                !selected,
                !choice.next_question_id
              );
            }}>
            {choice.title}
          </Text>
        </Flex>
      ))}

      {question.answer_type === 'primary_question_group_field' &&
        question.question_groups.map(
          (question_group: any) =>
            <React.Fragment key={question_group.id}>
              <QuestionGroupField
                title={question.title}
                id={question_group.id}
                placeholder={question_group.title}
                handleChangeInputGroup={handleChangeInputGroup}
                answers={question_group.answers}
              />
            </React.Fragment>
        )}
    </>
  );
};


export default QuestionApplicationTypeField;
