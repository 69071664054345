import * as React from 'react';
import { ChakraProvider  } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import Router from './routes/routes';
import { store } from './app/store';
import theme from './theme'

const App = () => (
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <Router />
    </ChakraProvider>
  </Provider>
);

export default App

