import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  styles: {
    global: {
      html: {
        fontSize: '18px'
      }
    }
  },
  colors: {
    "blue": {
      "50": "#EAE9FC",
      "100": "#C4C1F6",
      "200": "#9E99F0",
      "300": "#7871EA",
      "400": "#5249E4",
      "500": "#2C21DE",
      "600": "#231AB2",
      "700": "#1A1485",
      "800": "#110D59",
      "900": "#09072C"
    },
    "green": {
      "50": "#E9FCF2",
      "100": "#C1F6D9",
      "200": "#98F0C1",
      "300": "#70EBA9",
      "400": "#48E590",
      "500": "#117740",
      "600": "#19B360",
      "700": "#138648",
      "800": "#0D5930",
      "900": "#062D18"
    },
    "neutral": {
      "50": "#F2F2F2",
      "100": "#DBDBDB",
      "200": "#C4C4C4",
      "300": "#ADADAD",
      "400": "#969696",
      "500": "#7C7C7C",
      "600": "#666666",
      "700": "#4D4D4D",
      "800": "#333333",
      "900": "#1A1A1A"
    }
  },
  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
  },
  fontSizes: {
    xs: "0.78rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.33rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
})

export default theme