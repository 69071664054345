import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

const Footer = () => (
  <Flex>
    <Box flex={2}>
      <Image src='/images/logo.png' alt='Truoffer logo' height="32px" mb="4"/>
      <Text size='md' fontWeight="light">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua.
      </Text>
    </Box>
    <Box flex={1} pt="2" px="2">
      <Text size='lg' fontWeight='semibold'>Contact Us</Text>
      <Text size='md' fontWeight="light">info@truoffer.com</Text>
      <Text size='md' fontWeight="light">(800) 255-9080</Text>
    </Box>
    <Box flex={1} pt="2" px="2">
      <Text size='lg' fontWeight='semibold'>Legal</Text>
      <Text size='md' fontWeight="light">Privacy Policy</Text>
      <Text size='md' fontWeight="light">Terms of Use</Text>
    </Box>
  </Flex>
);

export default Footer;