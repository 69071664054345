import React, { useEffect } from 'react';
import axios from 'axios';
import { HStack, Box, Button } from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import QuestionnaireLayout from '../layouts/QuestionnaireLayout';
import QuestionnaireV2 from '../components/QuestionnaireV2';
import Footer from '../components/Footer';

import {
  setSelectedPanelID,
  setSelectedPanelAnswers,
  submitPanelAnswers,
  fetchQuestions,
  setPanelQuestions,
} from '../ducks/questionPanelsSlice';

interface QuestionChoice {
  id: number;
  title: string;
  position: number;
  next_question_id: number;
  selected: boolean;
}

interface Question {
  id: number;
  title: string;
  position: number;
  active: boolean;
  answer_type: string;
  placeholder_text: string;
  question_groups: Question[];
  question_choices: QuestionChoice[];
  answers: number[];
}

const ContinueApplication = () => {
  const user = useAppSelector((values) => values.auth.user);
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const isJoint = searchParams.get('co_borrower_id');

  const dispatch = useAppDispatch();
  const {
    selectedPanelID,
    questionPanels,
    panelQuestions,
    answers,
    isLoading,
  } = useAppSelector((values) => values.questionPanels);
  // const [panelQuestions, setPanelQuestions] = useState(panelQuestions);

  const selectChoice = (
    questionId: number,
    nextQuestionId?: number | null | undefined,
    choiceId?: number,
    selected?: boolean,
    resetChoice?: boolean
  ) => {
    const questionsCopy = [...panelQuestions];
    dispatch(
      setSelectedPanelAnswers({
        userId: user && user.id,
        applicationId: id,
        resetChoice,
        questionId,
        choiceId,
        selected,
      })
    );

    // const updatedQuestionIndex = questionsCopy.findIndex(
    //   (question) => question.id === +nextQuestionId
    // );
    // const parentQuestionIndex = questionsCopy.findIndex(
    //   (question) => question.id === +questionId
    // );
    //
    // // RESET CHOICES
    // questionsCopy[parentQuestionIndex].question_choices.forEach((choice) => {
    //   const questionIndex = questionsCopy.findIndex(
    //     (question) => question.id === +choice.next_question_id
    //   );
    //   if (questionIndex && questionsCopy[questionIndex]) {
    //     questionsCopy[questionIndex].active = false;
    //   }
    // });
    // questionsCopy[updatedQuestionIndex].active = true;
    dispatch(
      setPanelQuestions({
        nextQuestionId,
        questionId,
      })
    );
  };

  // TODO:
  // const fetchQuestions = async () => {
  //   const response = await axios.get(
  //     `${'https://api-borrowers.safeboxiq.info'}/api/v1/question_panels/${selectedPanelID}?user_application_id=${id}`
  //   );
  //   setPanelQuestions(response.data.question_panel.questions);
  // };

  const handlePreviousPanel = () => {
    if (questionPanels) {
      let panelIndex = questionPanels.findIndex(
        (panel) => panel.id === selectedPanelID
      );

      if (panelIndex > 0) {
        panelIndex -= 1;
        dispatch(setSelectedPanelID(questionPanels[panelIndex].id));
      }
    }
  };

  const handleNextPanel = async () => {
    const currentPanelId = selectedPanelID;
    dispatch(
      submitPanelAnswers({
        userId: user && user.id,
        applicationId: id,
        currentPanelId,
        answers,
      })
    );

    if (questionPanels) {
      let panelIndex = questionPanels.findIndex(
        (panel) => panel.id === selectedPanelID
      );
      if (panelIndex < questionPanels.length - 1) {
        panelIndex += + 1;
        dispatch(setSelectedPanelID(questionPanels[panelIndex].id));
      } else if (panelIndex >= questionPanels.length - 1) {
          if (user) {
            if (isJoint) {
              const response = await axios.post(
                `${'https://api-borrowers.safeboxiq.info'}/api/v1/users/${
                  user.id
                }/user_applications/${id}/done`
              );
              alert('Co-borrower application done!');
              window.location.href = `/applications/`;
            } else {
              const response = await axios.post(
                `${'https://api-borrowers.safeboxiq.info'}/api/v1/users/${
                  user.id
                }/user_applications/${id}/done`
              );
              console.log(response.data.type);
              if (response.data.type === 'joint') {
                alert('Primary borrower application done!');
                window.location.href = `/applications/${id}?co_borrower_id=${response.data.borrower_id}`;
              } else {
                alert('Application done!');
                window.location.href = `/applications/`;
              }
          }
        }
      }
    }
  };

  const handleChangeInputGroup = (
    questionId: number,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const element = event.currentTarget as HTMLInputElement;
    const { value } = element;

    dispatch(
      setSelectedPanelAnswers({
        userId: user && user.id,
        applicationId: id,
        textInput: value,
        resetChoice: false,
        questionId,
      })
    );
  };

  const handleChangeNumber = (questionId: number, value: number) => {
    dispatch(
      setSelectedPanelAnswers({
        userId: user && user.id,
        applicationId: id,
        textInput: value,
        resetChoice: false,
        questionId,
      })
    );
  };

  useEffect(() => {
    if (selectedPanelID && id) {
      dispatch(fetchQuestions({ selectedPanelID, id }));
    }
  }, [selectedPanelID, id]);

  useEffect(() => {
    if (panelQuestions) {
      panelQuestions.forEach((question) => {
        if (question.answers) {
          question.answers.forEach((answer) => {
            dispatch(
              setSelectedPanelAnswers({
                userId: user && user.id,
                applicationId: id,
                questionId: question.id,
                choiceId: answer,
                selected: true,
                resetChoice: false,
              })
            );
          });
        }
      });
    }
  }, []);

  return (
    <QuestionnaireLayout>
      <Box px="16" py="2" bg="white" borderLeft="1px" color="gray.800">
        <QuestionnaireV2
          questions={panelQuestions}
          selectChoice={selectChoice}
          handleChangeInputGroup={handleChangeInputGroup}
          handleChangeNumber={handleChangeNumber}
        />


        <Box pb="16" bg="white">
          <HStack spacing="10px">
            {!isLoading && (
              <>
              <Button onClick={handlePreviousPanel} width="180px">Go back</Button>
              <Button onClick={handleNextPanel} colorScheme="blue" width="180px">
              Next
              </Button>
              </>
            )}
          </HStack>
        </Box>
        <Footer />
      </Box>
    </QuestionnaireLayout>
  );
};

export default ContinueApplication;
