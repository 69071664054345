import React, { useState } from 'react';
import { Image, Text, Flex } from '@chakra-ui/react';

type QuestionMultipleChoiceFieldProps = {
  choice: any;
  question: any;
  multiple: boolean;
  choiceSelected: boolean;
  selectChoice: (
    questionId: number,
    nextQuestionId?: number | null | undefined,
    choiceId?: number,
    selected?: boolean
  ) => void;
};

const QuestionMultipleChoiceField = ({
                                       choice,
                                       question,
                                       selectChoice,
                                       multiple,
                                       choiceSelected
                                     }: QuestionMultipleChoiceFieldProps) => {
  const [selected, setSelected] = useState(choiceSelected);

  return (
    <Flex alignItems="center">
      {selected && (
        <Image src='/images/check.png' alt='Checked Icon' height='14px' mr='2' ml="-8"/>
      )}
      <Text
        size='md'
        fontWeight='semibold'
        color={selected ? 'gray.900' : 'gray.500'}
        _active={{ color: 'gray.900' }}
        _hover={{ color: 'gray.900' }}
        cursor='pointer'
        onClick={() => {
          if (multiple) {
            setSelected(!selected);
          }
          selectChoice(
            question.id,
            choice.next_question_id,
            choice.id,
            !selected
          );
        }}
      >
        {choice.title}
      </Text>
    </Flex>
  )
}

export default QuestionMultipleChoiceField;
