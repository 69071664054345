import axios from 'axios';

const API = axios.create({
  baseURL: `https://api-borrowers.safeboxiq.info/api/v1`,
});

API.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem('user') || null;
    if (user.token) {
      config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default API;
