import React, { useEffect, useState } from 'react';
import { Container, Flex, Link, Stack, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';

import { ArrowForwardIcon } from '@chakra-ui/icons';
import Application from '../components/Application';
import DashboardLayout from '../layouts/DashboardLayout';

import { useAppSelector } from '../app/hooks';

interface UserApplications {
  id: number;
  name: string;
  updated_at: string;
  status: string;
  application_type: string;
}

const Applications = () => {
  const user = useAppSelector((values) => values.auth.user);
  const [applications, setApplications] = useState<UserApplications[] | []>([]);

  const fetchApplications = async () => {
    if (user) {
      const response = await axios.get(
        `${'https://api-borrowers.safeboxiq.info'}/api/v1/users/${
          user.id
        }/user_applications`
      );
      setApplications(response.data.user_applications);
    }
  };

  const handleNewApplication = async () => {
    if (user) {
      const response = await axios.post(
        `${'https://api-borrowers.safeboxiq.info'}/api/v1/users/${
          user.id
        }/user_applications`,
        {
          name: 'Individual Application',
        }
      );
      window.location.href = `/applications/${response.data.user_application.id}`;
    }
  };

  useEffect(() => {
    fetchApplications();
  }, [user]);

  return (
    <DashboardLayout>
      <>
        <Container maxW="4xl" pt="16">
          <Flex alignItems="center" justifyContent="space-between">
            {user && (
              <Text fontSize="4xl">
                <strong>
                  Welcome, {user.firstName} {user.lastName}!
                </strong>
              </Text>
            )}
            <Link
              onClick={handleNewApplication}
              style={{ textDecoration: 'none' }}
            >
              <strong>
                Start a New Application
                <ArrowForwardIcon fontSize="xl" />
              </strong>
            </Link>
          </Flex>

          <Stack spacing="16px" py={8}>
            {user &&
              applications.map((app) => (
                <Link
                  key={`application-${app.id}`}
                  as={RouterLink}
                  to={app.id.toString()}
                  style={{ textDecoration: 'none' }}
                >
                  <Application
                    id={app.id}
                    key={app.id}
                    title={app.name}
                    dateStarted={app.updated_at}
                    type={app.status}
                    applicationType={app.application_type}
                  />
                </Link>
              ))}
            ;
          </Stack>
        </Container>
      </>
    </DashboardLayout>
  );
};

export default Applications;
