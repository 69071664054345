import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Container,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Grid,
  GridItem,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import DashboardLayout from '../layouts/DashboardLayout';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { updateUser } from '../ducks/auth/auth-slice';

const UserProfileSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
});

const UserProfile = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const user = useAppSelector((values) => values.auth.user);
  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => setEditMode(!editMode);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (user && user.id) || '',
      first_name: (user && user.firstName) || '',
      last_name: (user && user.lastName) || '',
      primary_phone: (user && user.primaryPhone) || '',
      email: (user && user.email) || '',
    },
    validationSchema: UserProfileSchema,
    onSubmit: async (values) => {
      dispatch(updateUser(values))
        .unwrap()
        .then(() => {
          toast({
            title: 'Account successfully updated',
            status: 'success',
            duration: 1000,
          });
        })
        .catch(() => {});
    },
  });
  return (
    <DashboardLayout>
      <Container maxW="4xl" pt="16">
        {user && (
          <Text fontWeight="bold" fontSize="42px" mb={5}>
            My Profile
          </Text>
        )}
        <form onSubmit={formik.handleSubmit}>
          <Grid templateColumns="repeat(2, 1fr)" gap={10}>
            <GridItem colSpan={1}>
              <VStack>
                <FormControl>
                  {formik.touched.first_name && formik.errors.first_name && (
                    <Text fontSize="sm" color="red">
                      {formik.errors.first_name}
                    </Text>
                  )}
                  <InputGroup>
                    <Input
                      id="first_name"
                      name="first_name"
                      type="first_name"
                      variant="filled"
                      placeholder="First name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.first_name}
                      disabled={!editMode}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  {formik.touched.email && formik.errors.email && (
                    <Text fontSize="sm" color="red">
                      {formik.errors.email}
                    </Text>
                  )}
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      fontSize="1.2em"
                    >
                      <EmailIcon />
                    </InputLeftElement>

                    <Input
                      id="email"
                      name="email"
                      type="email"
                      variant="filled"
                      placeholder="Email address"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      disabled={!editMode}
                    />
                  </InputGroup>
                </FormControl>
              </VStack>
            </GridItem>
            <GridItem colSpan={1}>
              <VStack>
                <FormControl>
                  {formik.touched.last_name && formik.errors.last_name && (
                    <Text fontSize="sm" color="red">
                      {formik.errors.last_name}
                    </Text>
                  )}
                  <InputGroup>
                    <Input
                      id="last_name"
                      name="last_name"
                      type="last_name"
                      variant="filled"
                      placeholder="Last name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.last_name}
                      disabled={!editMode}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      fontSize="1.2em"
                    >
                      <PhoneIcon />
                    </InputLeftElement>
                    <Input
                      id="primary_phone"
                      name="primary_phone"
                      type="primary_phone"
                      variant="filled"
                      placeholder="Primary phone"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.primary_phone}
                      disabled={!editMode}
                    />
                  </InputGroup>
                </FormControl>
              </VStack>
            </GridItem>
          </Grid>

          <FormControl mt={5}>
            <Button
              colorScheme="neutral"
              onClick={() => toggleEditMode()}
              type="button"
              px="16"
              mr="4"
            >
              {editMode ? 'Cancel' : 'Edit'}
            </Button>
            <Button
              colorScheme="blue"
              isLoading={formik.isSubmitting}
              disabled={!formik.isValid || formik.isSubmitting}
              px="16"
              type="button"
            >
              Save
            </Button>
          </FormControl>
        </form>
      </Container>
    </DashboardLayout>
  );
};

export default UserProfile;
