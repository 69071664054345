import React from 'react';
import {
  Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  Text,
  Tooltip,
  SliderThumb,
} from '@chakra-ui/react';

type QuestionNumberFieldProps = {
  id: number;
  placeholder: string;
  answers: string[];
  handleChangeNumber?: (id: number, event: any) => void;
};

const QuestionNumberField = ({
  id,
  placeholder,
  handleChangeNumber,
  answers,
}: QuestionNumberFieldProps) => {
  const [sliderValue, setSliderValue] = React.useState(
    (answers && parseInt(answers[0], 10)) || 10000
  );

  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <Box width="100%">
      <Text mt="-8">$ {sliderValue}</Text>

    <Slider
      value={sliderValue}
      min={0}
      max={30000}
      step={100}
      colorScheme="blue"
      onChange={(v) => {
        if (handleChangeNumber) {
          handleChangeNumber(id, v);
          setSliderValue(v);
        }
      }}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <SliderTrack height="15px">
        <SliderFilledTrack/>
      </SliderTrack>
      <Tooltip
        hasArrow
        bg="blue.600"
        color="white"
        placement="top"
        isOpen={showTooltip}
        label={`$${sliderValue}`}
      >
        <SliderThumb boxSize={6} bg="blue.600">
          <Box />
        </SliderThumb>
      </Tooltip>
    </Slider>
    </Box>
  );
};

export default QuestionNumberField;
