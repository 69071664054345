import React from 'react';
import {
  Box,
  Image,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

interface NavbarProps {
  onSignout: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onSignout }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box bg='white' p="6">
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <Box>
          <Image src='/images/logo.png' alt='truoffer logo' height="48px"/>
        </Box>

        <Flex alignItems="center">
          <Image src="/images/phone.png" alt="telephone icon" height="18px" mr="2"/>
          <Link
            as={RouterLink}
            color="blue"
            fontWeight="medium"
            to="/support"
            textDecoration="underline"
            mr={4}
          >
            (800) 251-9080
          </Link>
          <Menu>
            <MenuButton
              fontWeight="normal"
              cursor="pointer"
              onClick={isOpen ? onClose : onOpen}
            >
              My Account
              {isOpen ? (
                <ChevronUpIcon fontSize="lg" />
              ) : (
                <ChevronDownIcon fontSize="lg" />
              )}
            </MenuButton>
            <MenuList>
              <MenuItem as={RouterLink} to="/applications">
                My applications
              </MenuItem>
              <MenuItem as={RouterLink} to="/profile">
                My Profile
              </MenuItem>
              <MenuItem onClick={onSignout}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Navbar;
