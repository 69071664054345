import * as React from 'react';
import { Box } from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import { useAppDispatch } from '../app/hooks';
import { signout } from '../ducks/auth/auth-slice';

type LayoutProps = {
  children: JSX.Element;
};

const DashboardLayout = ({ children }: LayoutProps) => {
  const dispatch = useAppDispatch();

  const handleSignout = () => {
    dispatch(signout());
    localStorage.clear();
  };

  return (
    <Box h="calc(100vh - 126px)">
      <Navbar onSignout={handleSignout} />
      <Box h="100%" bg="white">
        {children}
      </Box>
    </Box>
  );
};

export default DashboardLayout