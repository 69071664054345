import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Input, Text, VStack } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles.css';
import QuestionMultipleChoiceField from './QuestionMultipleChoiceField';
import QuestionSingleChoiceField from './QuestionSingleChoiceField';
import QuestionGroupField from './QuestionGroupField';
import QuestionNumberField from './QuestionNumberField';
import QuestionArrayField from './QuestionArrayField';
import QuestionApplicationTypeField from './QuestionApplicationTypeField';
import { setSelectedPanelAnswers } from '../ducks/questionPanelsSlice';

import { useAppDispatch, useAppSelector } from '../app/hooks';

interface Choice {
  id: number;
  title: string;
  position: number;
  next_question_id: number;
  selected: boolean;
}

interface Question {
  id: number;
  title: string;
  active: boolean;
  position: number;
  answer_type: string;
  placeholder_text: string;
  question_groups: Question[];
  question_choices: Choice[];
  answers: any;
}

interface QuestionnaireProps {
  questions: Question[]
  selectChoice: (
    questionId: number,
    nextQuestionId?: number | null | undefined,
    choiceId?: number
  ) => void;
  handleChangeInputGroup?: (
    id: number,
    event: React.FormEvent<HTMLInputElement>
  ) => void;
  handleChangeNumber?: (id: number, value: number) => void;
}


const QuestionnaireV2 = ({
  questions,
  selectChoice,
  handleChangeInputGroup,
  handleChangeNumber = () => {},
}: QuestionnaireProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [searchParams, setSearchParams] = useSearchParams();
  const isJoint = searchParams.get('co_borrower_id');
  const dispatch = useAppDispatch();
  const user = useAppSelector((values) => values.auth.user);
  const { id } = useParams();

  const { panelQuestions } = useAppSelector((values) => values.questionPanels);

  useEffect(() => {
    if (panelQuestions) {
      panelQuestions.forEach((question) => {
        if (question.answers) {
          question.answers.forEach((answer) => {
            dispatch(
              setSelectedPanelAnswers({
                userId: user && user.id,
                applicationId: id,
                questionId: question.id,
                choiceId: answer,
                selected: true,
                resetChoice: false,
              })
            );
          });
        }
      });
    }
  }, []);

  return (
    <>
      {questions.map(
        (question) =>
          question.active && (
            <React.Fragment key={question.id}>
              {question.answer_type !== 'application_type' || !isJoint ? (
                <Text fontSize="2xl" fontWeight="bold" mb="25px">
                  <strong>{question.title}</strong>
                </Text>
              ) : null}

              <VStack spacing={6} alignItems="flex-start" mb={10  }>
                {question.answer_type === 'application_type' && (
                  <QuestionApplicationTypeField
                    choices={question.question_choices}
                    question={question}
                    selectChoice={selectChoice}
                    answers={question.answers}
                    handleChangeInputGroup={handleChangeInputGroup}
                  />
                )}

                {question.answer_type === 'single_choice' && (
                  <QuestionSingleChoiceField
                    choices={question.question_choices}
                    question={question}
                    selectChoice={selectChoice}
                    answers={question.answers}
                  />
                )}

                {question.answer_type === 'multiple_choice' &&
                  question.question_choices.map((choice, index) => (
                    <QuestionMultipleChoiceField
                      key={`choice-${question.id}`}
                      choice={choice}
                      question={question}
                      selectChoice={selectChoice}
                      multiple={question.answer_type === 'multiple_choice'}
                      choiceSelected={question.answers.includes(choice.id)}
                    />
                  ))}
                {question.answer_type === 'text_input' && (
                  <Input placeholder={question.placeholder_text} />
                )}

                {question.answer_type === 'text_input_array' && (
                  <QuestionArrayField
                    questionId={question.id}
                    placeholder={question.placeholder_text}
                    answers={question.answers}
                  />
                )}

                {question.answer_type === 'primary_question_group_field' &&
                  question.question_groups.map(
                    (question_group) =>
                      question.active && (
                        <React.Fragment key={question_group.id}>
                          <QuestionGroupField
                            title={question.title}
                            id={question_group.id}
                            placeholder={question_group.title}
                            handleChangeInputGroup={handleChangeInputGroup}
                            answers={question_group.answers}
                          />
                        </React.Fragment>
                      )
                  )}

                {question.answer_type === 'date' && (
                  <DatePicker
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                  />
                )}

                {question.answer_type === 'number' && (
                  <>
                    <h3>{question.placeholder_text}</h3>
                    <QuestionNumberField
                      id={question.id}
                      placeholder={question.placeholder_text}
                      answers={question.answers}
                      handleChangeNumber={handleChangeNumber}
                    />
                  </>
                )}
              </VStack>
            </React.Fragment>
          )
      )}
    </>
  );
};

export default QuestionnaireV2;
