import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../api';

interface QuestionAnswerState {
  id: number;
  answers: AnswerState[] | [];
}

interface AnswerState {
  choice_id?: number;
  answer_text?: string;
}

interface AnswerPayloadState {
  user_id: number;
  user_application_id: number;
  questions: QuestionAnswerState[] | [];
}

interface QuestionPanel {
  id: number;
  name: string;
  position: number;
}

interface QuestionChoice {
  id: number;
  title: string;
  position: number;
  next_question_id: number;
  selected: boolean;
}

interface PanelQuestion {
  id: number;
  title: string;
  position: number;
  active: boolean;
  answer_type: string;
  placeholder_text: string;
  question_groups: PanelQuestion[];
  question_choices: QuestionChoice[];
  answers: number[];
}

interface QuestionPanelState {
  questionPanels: QuestionPanel[] | null;
  selectedPanelID: number | null;
  isLoading: boolean;
  errorMessage: string | null | undefined;
  answers: AnswerPayloadState | null;
  panelQuestions: PanelQuestion[] | [];
  arrayFieldAnswers: any;
}

const initialState: QuestionPanelState = {
  questionPanels: [],
  selectedPanelID: null,
  isLoading: false,
  errorMessage: null,
  answers: null,
  panelQuestions: [],
  arrayFieldAnswers: [],
};

export const fetchQuestionPanels = createAsyncThunk(
  'questionPanel/fetchQuestionPanels',
  async () => {
    const response = await API.get('/question_panels');
    return response.data.question_panels.filter(
      (panel: any) => !panel.display_for_joint
    );
  }
);

export const fetchQuestionPanelsJoint = createAsyncThunk(
  'questionPanel/fetchQuestionPanelsJoint',
  async () => {
    const response = await API.get('/question_panels');
    return response.data.question_panels.filter(
      (panel: any) => panel.display_for_joint
    );
  }
);

export const fetchQuestions = createAsyncThunk(
  'questionPanel/fetchPanelQuestions',
  async (data: { selectedPanelID: number; id: string | null }, thunkAPI) => {
    const response = await API.get(
      `${'https://api-borrowers.safeboxiq.info'}/api/v1/question_panels/${
        data.selectedPanelID
      }?user_application_id=${data.id}`
    );
    return response.data.question_panel.questions;
  }
);

const questionPanelsSlice = createSlice({
  name: 'questionPanels',
  initialState,
  reducers: {
    setSelectedPanelID(state, action) {
      state.selectedPanelID = action.payload;
      if (state.questionPanels) {
        const panelIndex = state.questionPanels.findIndex(
          (panel) => panel.id === action.payload
        );
        const selectedPanel = state.questionPanels[panelIndex];
        // state.answers = {};
      }
    },
    submitPanelAnswers(state, action) {
      const data = { ...action.payload.answers };
      const { userId, applicationId } = action.payload;

      try {
        if (data.questions) {
          const questionsCopy = [...data.questions];
          if (state.arrayFieldAnswers && state.arrayFieldAnswers.answers) {
            questionsCopy.push({
              id: state.arrayFieldAnswers.id,
              answers: [...state.arrayFieldAnswers.answers],
            });
          }
          data.questions = questionsCopy;

          API.post(
            `/question_panels/${action.payload.currentPanelId}/answers_bulk`,
            data
          );

          state.arrayFieldAnswers = [];
          // todo: deconste
          state.answers = {
            user_id: userId,
            user_application_id: applicationId,
            questions: [],
          };
        } else {
          const questionsCopy = [];
          if (state.arrayFieldAnswers && state.arrayFieldAnswers.answers) {
            questionsCopy.push({
              id: state.arrayFieldAnswers.id,
              answers: [...state.arrayFieldAnswers.answers],
            });
          }
          data.questions = questionsCopy;
          data.user_id = userId;
          data.user_application_id = applicationId;

          API.post(
            `/question_panels/${action.payload.currentPanelId}/answers_bulk`,
            data
          );
        }

        // if (state.arrayFieldAnswers && state.arrayFieldAnswers.answers) {
        //   questionsCopy.push({
        //     id: state.arrayFieldAnswers.id,
        //     answers: [...state.arrayFieldAnswers.answers],
        //   });
        // }
        // data.questions = questionsCopy;
        //
        // API.post(
        //   `/question_panels/${action.payload.currentPanelId}/answers_bulk`,
        //   data
        // );
        // console.log('data');
        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    setPanelQuestions(state, action) {
      const { nextQuestionId, questionId } = action.payload;
      const questionsCopy = [...state.panelQuestions];

      if (!nextQuestionId) {
        const parentQuestionIndex = questionsCopy.findIndex(
          (question) => question.id === +questionId
        );

        // RESET CHOICES
        questionsCopy[parentQuestionIndex].question_choices.forEach(
          (choice) => {
            const questionIndex = questionsCopy.findIndex(
              (question) => question.id === +choice.next_question_id
            );
            if (questionIndex && questionsCopy[questionIndex]) {
              questionsCopy[questionIndex].active = false;
              questionsCopy[questionIndex].answers = [];
            }
          }
        );
      } else {
        const updatedQuestionIndex = questionsCopy.findIndex(
          (question) => question.id === +nextQuestionId
        );
        const parentQuestionIndex = questionsCopy.findIndex(
          (question) => question.id === +questionId
        );

        // RESET CHOICES
        questionsCopy[parentQuestionIndex].question_choices.forEach(
          (choice) => {
            const questionIndex = questionsCopy.findIndex(
              (question) => question.id === +choice.next_question_id
            );
            if (questionIndex && questionsCopy[questionIndex]) {
              questionsCopy[questionIndex].active = false;
            }
          }
        );
        questionsCopy[updatedQuestionIndex].active = true;
      }
      state.panelQuestions = questionsCopy;

    },
    setSelectedPanelAnswersArray(state, action) {
      const { questionId, answersArray } = action.payload;
      state.arrayFieldAnswers = {
        id: questionId,
        answers: answersArray,
      };
    },
    setSelectedPanelAnswers(state, action) {
      const {
        userId,
        applicationId,
        questionId,
        choiceId,
        selected,
        textInput,
        resetChoice,
      } = action.payload;


      if (!state.answers) {
        if (textInput) {
          state.answers = {
            user_id: userId,
            user_application_id: applicationId,
            questions: [
              {
                id: questionId,
                answers: [
                  {
                    answer_text: textInput,
                  },
                ],
              },
            ],
          };

          state.answers.user_id = userId;
          state.answers.user_application_id = applicationId;

          const questionsCopy = [...state.answers.questions];
          const questionIndex = questionsCopy.findIndex(
            (question) => question.id === questionId
          );

          if (questionIndex !== -1) {
            questionsCopy[questionIndex].answers = [{ answer_text: textInput }];
          } else {
            questionsCopy.push({
              id: questionId,
              answers: [
                {
                  answer_text: textInput,
                },
              ],
            });
          }
          state.answers = {
            user_id: userId,
            user_application_id: applicationId,
            questions: questionsCopy,
          };
        }

        if (choiceId) {
          state.answers = {
            user_id: userId,
            user_application_id: applicationId,
            questions: [
              {
                id: questionId,
                answers: [
                  {
                    choice_id: choiceId,
                  },
                ],
              },
            ],
          };
        }
      } else if (choiceId) {
          state.answers.user_id = userId;
          state.answers.user_application_id = applicationId;

          const questionsCopy = [...state.answers.questions];
          const questionIndex = questionsCopy.findIndex(
            (question) => question.id === questionId
          );

          if (questionIndex !== -1) {
            if (resetChoice) {
              questionsCopy[questionIndex].answers = [
                {
                  choice_id: choiceId,
                },
              ];
              state.answers = {
                user_id: userId,
                user_application_id: applicationId,
                questions: questionsCopy,
              };
            } else {
              const answersCopy = [...questionsCopy[questionIndex].answers];
              const answerIndex = answersCopy.findIndex(
                (answer) => answer.choice_id === choiceId
              );
              if (answerIndex !== -1) {
                if (!selected) {
                  answersCopy.splice(answerIndex, 1);
                }
              } else {
                answersCopy.push({
                  choice_id: choiceId,
                });
              }
              questionsCopy[questionIndex].answers = answersCopy;
            }
          } else {
            questionsCopy.push({
              id: questionId,
              answers: [
                {
                  choice_id: choiceId,
                },
              ],
            });
          }
          state.answers = {
            user_id: userId,
            user_application_id: applicationId,
            questions: questionsCopy,
          };
        } else if (textInput) {
          state.answers.user_id = userId;
          state.answers.user_application_id = applicationId;

          const questionsCopy = [...state.answers.questions];
          const questionIndex = questionsCopy.findIndex(
            (question) => question.id === questionId
          );

          if (questionIndex !== -1) {
            questionsCopy[questionIndex].answers = [{ answer_text: textInput }];
          } else {
            questionsCopy.push({
              id: questionId,
              answers: [
                {
                  answer_text: textInput,
                },
              ],
            });
          }
          state.answers = {
            user_id: userId,
            user_application_id: applicationId,
            questions: questionsCopy,
          };
        }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuestionPanels.pending, (state) => {
      state.questionPanels = [];
      state.isLoading = true;
      state.errorMessage = null;
    });
    builder.addCase(fetchQuestionPanels.fulfilled, (state, { payload }) => {
      state.questionPanels = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(fetchQuestionPanels.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.message;
    });

    builder.addCase(fetchQuestionPanelsJoint.pending, (state) => {
      state.questionPanels = [];
      state.isLoading = true;
      state.errorMessage = null;
    });
    builder.addCase(
      fetchQuestionPanelsJoint.fulfilled,
      (state, { payload }) => {
        state.questionPanels = payload;
        state.isLoading = false;
        state.errorMessage = null;
      }
    );
    builder.addCase(fetchQuestionPanelsJoint.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.message;
    });

    builder.addCase(fetchQuestions.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    });
    builder.addCase(fetchQuestions.fulfilled, (state, { payload }) => {
      state.panelQuestions = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(fetchQuestions.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.message;
    });
    // builder.addCase(submitPanelAnswers.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.errorMessage = null;
    // });
  },
});

export const {
  setSelectedPanelAnswers,
  setSelectedPanelAnswersArray,
  setSelectedPanelID,
  submitPanelAnswers,
  setPanelQuestions,
} = questionPanelsSlice.actions;
export default questionPanelsSlice.reducer;
