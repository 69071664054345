import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../ducks/auth/auth-slice';
import questionPanelsReducer from '../ducks/questionPanelsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    questionPanels: questionPanelsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
