import React, { useState, useEffect } from 'react';
import { Button, Flex, Text, Image } from '@chakra-ui/react';

type QuestionSingleChoiceFieldProps = {
  choices: any;
  question: any;
  answers: any;
  selectChoice: (
    questionId: number,
    nextQuestionId?: number | null | undefined,
    choiceId?: number,
    selected?: boolean,
    resetChoice?: boolean
  ) => void;
};

const QuestionSingleChoiceField = ({
                                     choices,
                                     question,
                                     selectChoice,
                                     answers
                                   }: QuestionSingleChoiceFieldProps) => {
  const [selected, setSelected] = useState(answers && answers[0]);
  useEffect(() => {
    console.log(selected)
  }, [selected])

  return (
    <>
      {question.question_choices.map((choice: any, index: number) => (
        <Flex
          key={choice.id}
          alignItems='center'
        >
          {selected === choice.id && (
            <Image src='/images/check.png' alt='Checked Icon' height='14px' mr='2' ml='-8' />
          )}
          <Text
            size='md'
            fontWeight='semibold'
            color={selected === choice.id ? 'gray.900' : 'gray.500'}
            _active={{ color: 'gray.900' }}
            _hover={{ color: 'gray.900' }}
            cursor='pointer'
            onClick={() => {
              setSelected(choice.id);
              selectChoice(
                question.id,
                choice.next_question_id,
                choice.id,
                !selected,
                !choice.next_question_id
              );
            }}>
            {choice.title}
          </Text>
        </Flex>
      ))}
    </>
  );
};

export default QuestionSingleChoiceField;
