import React, { useState, useEffect } from 'react';
import { Box, Input, Link, HStack } from '@chakra-ui/react';
import { setSelectedPanelAnswersArray } from '../ducks/questionPanelsSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';

type QuestionArrayFieldProps = {
  questionId: number;
  placeholder: string;
  handleChangeInputGroup?: (
    id: number,
    event: React.FormEvent<HTMLInputElement>
  ) => void;
  answers: string[];
};

const QuestionArrayField = ({
                              placeholder,
                              questionId,
                              handleChangeInputGroup,
                              answers
                            }: QuestionArrayFieldProps) => {
  const dispatch = useAppDispatch();
  const { arrayFieldAnswers } = useAppSelector(
    (values) => values.questionPanels
  );
  const [inputList, setInputList] = useState([{ location: '' }]);

  const handleRemoveClick = (index: number) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

    const data = list.map((o) => o.location);
    dispatch(
      setSelectedPanelAnswersArray({
        answersArray: data,
        questionId
      })
    );
  };

  const handleAddClick = () => {
    setInputList([...inputList, { location: '' }]);
  };

  const updateInputList = (value: string, index: number) => {
    const inputListCopy = [...inputList];

    inputListCopy[index] = { location: value };
    const data = inputListCopy.map((o) => o.location);
    setInputList(inputListCopy);
    dispatch(
      setSelectedPanelAnswersArray({
        answersArray: data,
        questionId
      })
    );
  };
  useEffect(() => {
    if (answers && answers.length > 0) {
      const answersArrayObject = answers.map((value) => ({ location: value }));
      setInputList(answersArrayObject);
    }
  }, []);

  const renderInputList = () =>
    inputList.map((input, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Box key={index}>
        <HStack justifyContent='space-between' pb='2'>
          <Input
            placeholder={placeholder}
            value={input.location}
            onChange={(e) => {
              updateInputList(e.target.value, index);
            }}
          />

          {inputList.length !== 1 && (
            <Link color='red.500' onClick={() => handleRemoveClick(index)}>
              {' '}
              Remove
            </Link>
          )}
        </HStack>

        {inputList.length - 1 === index && (
          <Link color='green.500' onClick={handleAddClick}>
            Add another location
          </Link>
        )}
      </Box>
    ));

  return <>{renderInputList()}</>;
};

export default QuestionArrayField;
