import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Link,
  Center,
  Container,
  Flex,
  Text,
  Input,
  Button,
  VStack,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  HStack, Image
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
});

const ForgotPassword = () => {
  const [instructionSent, setInstructionSet] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        setInstructionSet(true);
        setSubmitting(false);
      }, 2000);
    },
  });

  return (
    <Flex h='100vh'>
      <Box bg="#282828" display={{ base: 'none', md: 'flex'}} width="530px">
        <Image src="/images/logo-white.png" alt="truoffer logo" position="absolute" top="50" left="50" height="48px"/>
        <Center height="100%" color="white">
          <Container px="50px" mb="260px">
            <Text fontSize="42px" fontWeight="bold" fontFamily="Merriweather">Create or Reset Your
              Password</Text>
          </Container>
        </Center>
        <Box position="absolute" bottom="50" left="50" color="white">
          <Flex mb="8">
            <Image src="/images/phone-white.png" alt="phone icon"  height="26px" mr="4"/>
            <Text>+639 123 456 7890</Text>
          </Flex>
          <Flex>
            <Image src="/images/mail-white.png" alt="phone icon"  height="26px" mr="4"/>
            <Text>info@truoffer.com</Text>
          </Flex>
        </Box>
      </Box>
      <Center flex="1">
        <Container>
          <Box hidden={!instructionSent}>
            <Text>
              Password reset instructions has been sent to{' '}
              <strong>{formik.values.email}</strong>. Please check your email to
              confirm.
            </Text>
          </Box>

          <form onSubmit={formik.handleSubmit} hidden={instructionSent}>
            <VStack spacing={4} align="flex-start">
              <FormControl>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                {formik.touched.email && formik.errors.email && (
                  <Text fontSize="sm" color="red">
                    {formik.errors.email}
                  </Text>
                )}
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    <EmailIcon />
                  </InputLeftElement>
                  <Input
                    autoComplete="on"
                    id="email"
                    name="email"
                    type="email"
                    variant="filled"
                    placeholder="Enter email address"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </InputGroup>
              </FormControl>
              <HStack spacing={8}>
                <Button
                  isLoading={formik.isSubmitting}
                  disabled={
                    !(formik.isValid && formik.dirty) || formik.isSubmitting
                  }
                  type="submit"
                >
                  Send Reset Link
                </Button>
              </HStack>
              <Link
                to="/signin"
                as={RouterLink}
                fontSize="sm"
                color="gray.500"
              >
                Already have an account? <strong>Sign in</strong>
              </Link>
            </VStack>
          </form>
        </Container>
        <Image src="/images/logo.png" alt="phone icon"  height="32px" position="absolute" bottom="50" right="50"/>
      </Center>
    </Flex>
  );
};

export default ForgotPassword