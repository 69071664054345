import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Link,
  Center,
  Container,
  Flex,
  Text,
  Input,
  Button,
  VStack,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Checkbox,
  useToast, Box, Image
} from '@chakra-ui/react';
import { EmailIcon, LockIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAppDispatch} from '../app/hooks';
import { signupUser } from '../ducks/auth/auth-slice';

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  confirmEmail: Yup.string()
    .email('Email is invalid')
    .oneOf([Yup.ref('email')], 'Email does not match'),
  password: Yup.string()
    .min(6, 'Password entered is too short')
    .required('Password is required'),
  agreeToTerms: Yup.bool().oneOf([true], 'Please read terms and conditions'),
});
const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      email: '',
      confirmEmail: '',
      password: '',
      agreeToTerms: false,
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      dispatch(signupUser(values))
        .unwrap()
        .then(() => {
          toast({
            title: 'Account registered successfully',
            description: 'Redirecting you to signin page',
            status: 'success',
            duration: 1000,
          });
          setTimeout(() => {
            navigate('/applications');
          }, 1500);
        })
    }
  });

  return (
    <Flex h="100vh">
      <Box bg="#282828" display={{ base: 'none', md: 'flex'}} width="530px">
        <Image src="/images/logo-white.png" alt="truoffer logo" position="absolute" top="50" left="50" height="48px"/>
        <Center height="100%" color="white">
          <Container px="50px" mb="260px">
            <Text fontSize="42px" fontWeight="bold" fontFamily="Merriweather">Create or Reset Your
              Password</Text>
          </Container>
        </Center>
        <Box position="absolute" bottom="50" left="50" color="white">
          <Flex mb="8">
            <Image src="/images/phone-white.png" alt="phone icon"  height="26px" mr="4"/>
            <Text>+639 123 456 7890</Text>
          </Flex>
          <Flex>
            <Image src="/images/mail-white.png" alt="phone icon"  height="26px" mr="4"/>
            <Text>info@truoffer.com</Text>
          </Flex>
        </Box>
      </Box>
      <Center flex="1">
        <Container>
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4} align="flex-start">
              <FormControl>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                {formik.touched.email && formik.errors.email && (
                  <Text fontSize="sm" color="red">
                    {formik.errors.email}
                  </Text>
                )}
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    <EmailIcon />
                  </InputLeftElement>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    variant="filled"
                    placeholder="Enter email address"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="email">Confirm Email Address</FormLabel>
                {formik.touched.confirmEmail && formik.errors.confirmEmail && (
                  <Text fontSize="sm" color="red">
                    {formik.errors.confirmEmail}
                  </Text>
                )}
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    <EmailIcon />
                  </InputLeftElement>
                  <Input
                    id="email"
                    name="confirmEmail"
                    type="email"
                    variant="filled"
                    placeholder="Enter email address"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.confirmEmail}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">Password</FormLabel>
                {formik.touched.password && formik.errors.password && (
                  <Text fontSize="sm" color="red">
                    {formik.errors.password}
                  </Text>
                )}
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    <LockIcon />
                  </InputLeftElement>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    variant="filled"
                    placeholder="Enter password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                </InputGroup>
              </FormControl>
              <Checkbox
                name="agreeToTerms"
                isChecked={formik.values.agreeToTerms}
                onChange={formik.handleChange}
                size="sm"
                color="gray.500"
              >
                I confirm Cookie Policy.
              </Checkbox>
              <Button
                isLoading={formik.isSubmitting}
                disabled={
                  !(formik.isValid && formik.dirty) || formik.isSubmitting
                }
                type="submit"
              >
                Sign up
              </Button>
              <Link
                to="/signin"
                as={RouterLink}
                fontSize="xs"
                color="gray.500"
              >
                Already have an account? <strong>Sign in</strong>
              </Link>
            </VStack>
          </form>
        </Container>
        <Image src="/images/logo.png" alt="phone icon"  height="32px" position="absolute" bottom="50" right="50"/>
      </Center>
    </Flex>
  );
};

export default Signup