import * as React from 'react';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { saveAs } from 'file-saver';
import axios from 'axios';

type ApplicationProps = {
  id: any;
  title: string;
  dateStarted: string;
  type: string;
  applicationType: string;
};

const Application = ({
  id,
  title,
  dateStarted,
  type,
  applicationType,
}: ApplicationProps) => {
  const getVariantByType = (variant: string) => {
    switch (variant) {
      case 'inProgress':
        return 'outline';
      case 'completed':
        return 'solid';
      default:
        return '';
    }
  };
  const getColorSchemeByType = (colorScheme: string) => {
    switch (colorScheme) {
      case 'inProgress':
        return 'messenger';
      case 'completed':
        return 'blue';
      default:
        return '';
    }
  };

  const downloadSummary = async () => {
    try {
      const response = await axios.get(
        `https://api-borrowers.safeboxiq.info/api/v1/user_applications/${id}/pdf`,
        {
          responseType: 'blob',
        }
      );
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `user_application_${id}.pdf`);
    } catch (error) {
      console.error('Error downloading PDF', error);
    }
  };

  return (
    <Box w="100%" bg="white" boxShadow="lg" color="gray.800" borderRadius="xl">
      <HStack justifyContent="space-between" px="16" py="5">
        <VStack align="flex-start">
          <Text fontSize="2xl">
            <strong>{title}</strong>
          </Text>
          <Text>{dateStarted}</Text>
        </VStack>
        {type === 'completed' ? (
          <Button
            onClick={downloadSummary}
            variant={getVariantByType(type)}
            colorScheme={getColorSchemeByType(type)}
          >
            Download Summary
          </Button>
        ) : (
          <Button
            w="64"
            size="lg"
            variant={getVariantByType(type)}
            colorScheme={getColorSchemeByType(type)}
          >
            Continue Application
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default Application;
