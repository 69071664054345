import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../api';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  primaryPhone: string;
  email: string;
  userApplications: UserApplications[];
}

interface UserApplications {
  id: number;
  name: string;
  updated_at: string;
  status: string;
}

interface SigninPayload {
  email: string;
  password: string;
}

interface SignupPayload {
  email: string;
  password: string;
}

interface AuthState {
  user: User | null;
  token: string | null;
  isLoading: boolean;
  errorMessage: string | null | undefined;
}

interface UserProfilePayload {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  primary_phone: string;
}

const fetchUserFromLocalStorage = () => {
  const rawUser = localStorage.getItem('user');
  if (rawUser) return JSON.parse(rawUser);
  return null;
};

const userLocal = fetchUserFromLocalStorage();

const initialState: AuthState = {
  user: userLocal ? userLocal.user : null,
  token: userLocal ? userLocal.token : null,
  isLoading: false,
  errorMessage: null,
};

export const signupUser = createAsyncThunk(
  'auth/signupUser',
  async (payload: SignupPayload) => {
    const res = await API.post('/users', {
      first_name: 'Changeme',
      last_name: 'Changeme',
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password,
    });
    return res.data;
  }
);

export const signinUser = createAsyncThunk(
  'auth/signinUser',
  async (payload: SigninPayload) => {
    const res = await API.post('/login', {
      email: payload.email,
      password: payload.password,
    });
    return {
      user: {
        id: res.data.user.id,
        firstName: res.data.user.first_name,
        lastName: res.data.user.last_name,
        primaryPhone: res.data.user.primary_phone,
        email: res.data.user.email,
        userApplications: res.data.user.user_applications,
      },
      token: res.data.token,
    };
  }
);

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async (payload: UserProfilePayload) => {
    const res = await API.patch(
      `/users/${payload.id}`,
      {
        email: payload.email,
        first_name: payload.first_name,
        last_name: payload.last_name,
        primary_phone: payload.primary_phone,
      },
      {
        headers: {
          Authorization: `Bearer ${userLocal.token}`,
        },
      }
    );
    return {
      user: {
        id: res.data.user.id,
        email: res.data.user.email,
        firstName: res.data.user.first_name,
        lastName: res.data.user.last_name,
        primaryPhone: res.data.user.primary_phone,
        userApplications: res.data.user.user_applications,
      },
      token: res.data.token,
    };
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    signout(state) {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    // Signup
    builder.addCase(signupUser.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    });
    builder.addCase(signupUser.fulfilled, (state) => {
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(signupUser.rejected, (state) => {
      state.isLoading = false;
    });
    // Signin
    builder.addCase(signinUser.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    });
    builder.addCase(signinUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(signinUser.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setErrorMessage, signout } = authSlice.actions;
export default authSlice.reducer;
