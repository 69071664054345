import React from 'react';
import { Container, Box, Grid, GridItem, SkeletonText } from '@chakra-ui/react';
import { useAppSelector } from '../app/hooks';
import DashboardLayout from './DashboardLayout';
import QuestionPanels from '../components/QuestionPanels';

type LayoutProps = {
  children: JSX.Element;
};

const QuestionnaireLayout = ({ children }: LayoutProps) => {
  const { isLoading } = useAppSelector((values) => values.questionPanels);

  return (
    <DashboardLayout>
      <Container maxW="6xl" py="8">
        <Grid templateColumns="repeat(5, 1fr)" gap={10}>
          <GridItem colSpan={1}>
            <QuestionPanels />
          </GridItem>

          <GridItem colSpan={4}>
            <Box>
              {children}
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </DashboardLayout>
  );
};

export default QuestionnaireLayout